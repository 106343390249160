<template>
  <v-form ref="form">
    <h3>Sopimuspohja</h3>

    <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          v-model="selectedContractModel"
          item-text="name"
          item-value="_id"
          :items="contractModels"
          :rules="validations.required"
          return-object
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import validations from "@/validations";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      validations,
    };
  },

  computed: {
    ...mapState("contractmodel", ["contractModels"]),

    selectedContractModel: {
      get() {
        return this.$store.state.contractmodel.selectedContractModel;
      },
      set(val) {
        this.setSelectedContractModel(val);
      },
    },
  },

  methods: {
    ...mapMutations("contractmodel", ["setSelectedContractModel"]),

    validate() {
      return this.$refs.form.validate();
    },

    resetValidations() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
