<template>
  <div>
    <div v-for="(contract, idx) in contracts" :key="'a' + idx">
      <p style="font-size: 12px" :class="`${getSignatureColor(contract)}--text`">
        {{ getSignatureState(contract) }}
      </p>
      <p class="mb-1">
        Nro. {{ contract.contractNumber }} |
        <router-link
          style="text-decoration: none"
          :to="`/other-contracts?search=${contract.name} ${contract.contractNumber}`"
        >
          {{ contract.name }}</router-link
        >
        <span
          class="ml-2 error--text"
          style="cursor: pointer"
          @click="removeReference(contract._id)"
          ><small>Poista</small></span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  computed: {
    ...mapState("othercontract", ["contracts"]),
    ...mapState("contract", ["refRentalContract"]),
  },

  methods: {
    ...mapActions("contract", ["removeReferenceContract"]),

    removeReference(id) {
      if (
        confirm(
          "Haluatko varmasti poistaa liitoksen? Liitoksen poistaminen ei poista itse sopimusta."
        )
      ) {
        this.removeReferenceContract({
          referenceId: id,
          rentalContractId: this.refRentalContract._id,
        });
      }
    },
  },
};
</script>

<style></style>
