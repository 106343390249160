<template>
  <v-dialog v-model="dialog" width="650">
    <v-card>
      <v-card-title>Aktivoi sopimus</v-card-title>

      <!-- NEW CONTRACT -->

      <v-card-text v-if="currentContract.contractType == 'new'">
        <!-- Sending is enabled -->
        <div v-if="!currentUser.currentAccount.settings.confirmSignatures">
          <h3 v-if="isSigned()" class="mb-1">Sopimus on allekirjoitettu.</h3>
          <h3 v-else class="mb-1">
            Sopimusta ei ole allekirjoitettu Vuokranetissä, mutta allekirjoitusta ei vaadita.
          </h3>

          <!-- Email reveivers -->
          <div v-if="!currentUser.currentAccount.settings.sendContractSignEmail">
            <h3 class="mb-1">Sopimuksia ei lähetetä sähköpostilla.</h3>
            <p>Voit kytkeä ominaisuuden päälle tilin asetuksista.</p>
          </div>

          <div v-else>
            <h4>
              {{
                isSigned()
                  ? "Allekirjoitettu sopimus lähetetään seuraaviin osoitteisiin"
                  : "Allekirjoittamaton sopimus lähetetään seuraaviin osoitteisiin"
              }}
            </h4>
            <ul>
              <li v-for="(email, index) in getContractActivateEmails()" :key="'a' + index">
                {{ email }}
              </li>
            </ul>
          </div>
        </div>

        <div v-else>
          <div v-if="isSigned()">
            <h3 class="mb-1">Sopimus on allekirjoitettu.</h3>
            <!-- Email reveivers -->
            <div v-if="!currentUser.currentAccount.settings.sendContractSignEmail">
              <h3 class="mb-1">Sopimuksia ei lähetetä sähköpostilla.</h3>
              <p>Voit kytkeä ominaisuuden päälle tilin asetuksista.</p>
            </div>

            <div v-else>
              <h4>Allekirjoitettu sopimus lähetetään seuraaviin osoitteisiin</h4>
              <ul>
                <li v-for="(email, index) in getContractActivateEmails()" :key="'a' + index">
                  {{ email }}
                </li>
              </ul>
            </div>
          </div>

          <h3 v-else class="error--text">
            Sopimusta ei ole allekirjoitettu Vuokranetissä ja allekirjoitus vaaditaan.
          </h3>
        </div>

        <h3 v-if="!mandatoryPhotoOnContract()" class="error--text mt-2">
          Valokuva vuokrakohteesta vaaditaan ennen sopimuksen aktivoimista. Liitä se
          vuokrasopimuksen riviltä kameran kuvasta.
        </h3>
      </v-card-text>

      <!-- OLD CONTRACT -->

      <v-card-text v-else>
        <h3 v-if="currentUser.currentAccount.settings.sendContractSignEmail">
          Voimassa olevaa vuokrasopimusta ei toimiteta sähköpostitse.
        </h3>
      </v-card-text>

      <!-- SEND FORM WHEN ACTIVATE -->
      <v-card-text>
        <h3>
          Haluatko lähettää vuokralaisille lomakkeita täydennettäviksi aktivoinnin yhteydessä?
        </h3>

        <contract-activation-form ref="contractActivationForm"></contract-activation-form>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="sign" :disabled="disabledOptions">Aktivoi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins/mixins";
import ContractActivationForm from "./ContractActivationForm.vue";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  components: {
    ContractActivationForm,
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("contract", ["currentContract"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.contractActivationForm.resetFields();
        this.$emit("input", value);
      },
    },

    disabledOptions() {
      let disabled;
      if (this.currentContract.contractType == "old") return false;

      if (!this.currentUser.currentAccount.settings.confirmSignatures) {
        if (!this.mandatoryPhotoOnContract()) disabled = true;
        else disabled = false;
      } else {
        if (!this.isSigned() || !this.mandatoryPhotoOnContract()) disabled = true;
        else disabled = false;
      }

      return disabled;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.contractActivationForm.resetFields();
        });
      }
    },
  },

  methods: {
    sign() {
      if (this.$refs.contractActivationForm.validate())
        this.$emit("signcontract", this.currentContract);
    },

    isSigned() {
      // Signed with new method
      if (this.currentContract.signatureMethod) {
        if (this.currentContract.signatureMethod == "signhero") {
          return this.currentContract.signature.status == "completed" ? true : false;
        } else if (this.currentContract.signatureMethod == "vismaSign") {
          this.currentContract.vismaSign.status == "signed" ? true : false;
        } else if (this.currentContract.signatureMethod == "signaturePad") {
          return this.currentContract.signaturePad.status == "completed" ? true : false;
        }
      } else {
        // Old signhero method
        return this.currentContract.signature.status == "completed" ? true : false;
      }
    },

    getContractActivateEmails() {
      const emails = [];

      this.currentUser.currentAccount.settings.emailNotifications.forEach((user) => {
        if (user.resource.includes("contractActivate") && !emails.includes(user.email)) {
          emails.push(user.email);
        }
      });

      // Send to all users only if signaturePad is signed
      if (
        this.currentContract.signatureMethod == "signaturePad" &&
        this.currentContract.signaturePad.status == "completed"
      ) {
        // Landlords
        this.currentContract.landlords.forEach((landlord) => {
          if (landlord.email && !emails.includes(landlord.email)) emails.push(landlord.email);
        });

        // tenant email
        if (
          this.currentContract.tenant.email &&
          !emails.includes(this.currentContract.tenant.email)
        ) {
          emails.push(this.currentContract.tenant.email);
        }

        // Other tenants emails
        this.currentContract.otherTenants.forEach((tenant) => {
          if (tenant.email && !emails.includes(tenant.email)) emails.push(tenant.email);
        });
      }

      return emails;
    },

    mandatoryPhotoOnContract() {
      if (
        !this.currentUser.currentAccount.settings.mandatoryPhotoOnContract ||
        this.currentContract.contractType == "old"
      ) {
        return true;
      } else if (this.currentContract.contractType == "new") {
        return this.hasItems(this.currentContract.images);
      }
    },
  },
};
</script>

<style></style>
