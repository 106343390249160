<template>
  <v-dialog v-model="dialog" width="550">
    <v-card>
      <v-card-title v-if="tab === 0"
        >Irtisano sopimuksen {{ currentContract.contractNumber }} vuokralaisia</v-card-title
      >
      <v-card-title v-if="tab === 1"
        >Lisää sopimukselle {{ currentContract.contractNumber }} vuokralaisia</v-card-title
      >

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            <div>{{ item }}</div>
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- REMOVE -->
      <v-card-text v-if="tab === 0">
        <h3 v-if="!selectableTenantsFound" class="mb-2 error--text">
          Vuokralaisia ei voi enää irtisanoa vuokrasopimukselta
        </h3>

        <h4 class="mb-1 success--text">Nykyiset vuokralaiset</h4>

        <!-- ACTIVE TENANTS -->
        <div v-for="(tenant, idx) in activeTenants" :key="'a' + idx">
          <p :class="{ deleted: isRemoved(tenant.tenantId._id) }" class="ma-0">
            {{ tenant.name }}
            <span>
              <v-btn
                v-if="!isRemoved(tenant.tenantId._id) && canBeRemoved"
                icon
                small
                @click="selectTenant(tenant.tenantId._id)"
                ><v-icon small color="warning">mdi-redo</v-icon>
              </v-btn>

              <v-btn
                v-if="isRemoved(tenant.tenantId._id)"
                icon
                small
                @click="unSelectTenant(tenant.tenantId._id)"
              >
                <v-icon small color="success">mdi-undo</v-icon>
              </v-btn>
            </span>
          </p>
        </div>

        <!-- INACTIVE TENANTS -->
        <h4 v-if="inActiveTenants.length > 0" class="mb-1 mt-1 warning--text">
          Irtisanotut vuokralaiset
        </h4>

        <div v-for="(tenant, idx) in inActiveTenants" :key="'b' + idx">
          <p :class="{ deleted: isRemoved(tenant.tenantId._id) }" class="ma-0">
            {{ tenant.name }} ({{ formatDate(tenant.revokedDate) }})
            <span>
              <v-btn icon small @click="deleteRevokedDate(tenant)">
                <v-icon small color="success">mdi-redo</v-icon>
              </v-btn>
            </span>
          </p>
        </div>

        <div class="mt-2" v-if="this.selected.length > 0">
          <strong style="font-size: 12px"
            >Irtisanoaksesi valitut vuokralaiset sopimukselta, aseta irtisanomispäivä ja paina
            irtisano. Irtisanotut vuokralaiset voi palauttaa takaisin toimenpiteen jälkeen.</strong
          >
        </div>

        <!-- FORM -->
        <v-form ref="form">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="formatDate(revokedDate)"
                dense
                outlined
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                label="Irtisanomispäivä"
                v-prevent-manual-input
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="revokedDate"
              first-day-of-week="1"
              @input="menu = false"
              :allowed-dates="availableDates"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>

      <!-- ADD -->
      <v-card-text v-if="tab == 1"> </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!selectableTenantsFound" color="info" @click="submit">{{
          tab == 0 ? "Irtisano" : "Lisää"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tab: 0,
      tabs: [
        "Irtisano",
        // "Lisää"
      ],
      menu: false,
      startDate: null,
      revokedDate: null,
      selected: [],
      validations,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("contract", ["currentContract"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.selected = [];
          this.startDate = null;
          this.revokedDate = null;
        }
        this.$emit("input", value);
      },
    },

    activeTenants() {
      if (this.currentContract.tenant && this.currentContract.otherTenants) {
        const tenant = !this.currentContract.tenant.revokedDate
          ? [this.currentContract.tenant]
          : [];
        const others = this.currentContract.otherTenants.filter((tenant) => !tenant.revokedDate);
        return [...tenant, ...others];
      } else return [];
    },

    inActiveTenants() {
      if (this.currentContract.tenant && this.currentContract.otherTenants) {
        const tenant = this.currentContract.tenant.revokedDate ? [this.currentContract.tenant] : [];
        const others = this.currentContract.otherTenants.filter((tenant) => tenant.revokedDate);
        return [...tenant, ...others];
      } else return [];
    },

    selectableTenantsFound() {
      return this.activeTenants.length > 1;
    },

    canBeRemoved() {
      return this.activeTenants.length - this.selected.length > 1;
    },
  },

  methods: {
    ...mapActions("contract", ["setRevokedDateToTenant", "revertRevokedTenant"]),

    selectTenant(id) {
      this.selected.push(id);
      this.showPopup("Vuokralainen siirretty irtisanomislistalle", "success");
    },

    unSelectTenant(id) {
      const index = this.selected.findIndex((elId) => elId === id);
      if (index != -1) {
        this.selected.splice(index, 1);
        this.showPopup("Vuokralainen poistettu irtisanomislistalta", "success");
      }
    },

    isRemoved(id) {
      return this.selected.includes(id);
    },

    async submit() {
      try {
        if (this.selected.length === 0)
          return this.showPopup("Valitse vähintään yksi vuokralainen", "error");
        if (!this.$refs.form.validate()) return this.showPopup("Tarkista lomake", "error");

        await this.setRevokedDateToTenant({
          rentalContractId: this.currentContract._id,
          selected: this.selected,
          revokedDate: this.revokedDate,
        });

        this.selected = [];
        this.$refs.form.reset();

        this.showPopup("Vuokralainen poistettu nykyiseltä sopimukselta", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteRevokedDate(tenant) {
      if (
        confirm(
          `Haluatko palauttaa vuokralaisen ${tenant.name} sopimukselle ja poistaa vanhan irtisanomispäivän?`
        )
      ) {
        try {
          await this.revertRevokedTenant({
            rentalContractId: this.currentContract._id,
            tenantId: tenant.tenantId._id,
          });

          this.selected = [];
          this.$refs.form.reset();

          this.showPopup("Vuokralainen palautettu nykyiselle sopimukselle", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    availableDates(val) {
      const leaseType = this.currentContract.lease.leaseType;
      const contractStart = moment(this.currentContract.lease.startDate).format("YYYY-MM-DD");
      const contractEnd = this.currentContract.lease.endDate
        ? moment(this.currentContract.lease.endDate).format("YYYY-MM-DD")
        : null;

      if (leaseType === "Toistaiseksi voimassa oleva") {
        return moment(val).isAfter(contractStart);
      } else {
        return moment(val).isAfter(contractStart) && moment(val).isBefore(contractEnd);
      }
    },
  },
};
</script>

<style scoped>
.deleted {
  text-decoration: line-through;
}
</style>
