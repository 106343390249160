<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title
        ><span style="margin-right: 6px">{{ title }}</span>
        <span v-if="attachTo == 'apartment' && apartment">
          {{ apartment.address
          }}<span v-if="apartment.apartmentNumber">, {{ apartment.apartmentNumber }}</span></span
        ></v-card-title
      >

      <!-- TABS -->
      <v-card-text v-if="!editMode">
        <v-tabs v-model="tab" color="primary" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <v-card-text v-show="tab == 0">
        <h4>Voimassaolo</h4>

        <v-form ref="form">
          <v-row dense align="center">
            <v-col cols="10" sm="5" md="4" lg="3" style="padding-bottom: 0px; padding-top: 0px">
              <v-checkbox
                v-model="alwaysActive"
                style="margin-top: 0px"
                label="Lomake on aina aktiivinen"
                hide-details
                @change="changeOpenAlways"
              ></v-checkbox>
            </v-col>

            <v-col
              v-if="!alwaysActive"
              cols="10"
              sm="5"
              md="4"
              lg="3"
              style="padding-bottom: 0px; padding-top: 0px"
            >
              <v-checkbox
                v-model="showEndDate"
                style="margin-top: 0px"
                label="Voimassa määräajan"
                @change="endDate = null"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="!alwaysActive" dense>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(startDate)"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    label="Voimassa alkaen"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    hide-details
                    :rules="validations.required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :allowed-dates="allowedDatesStart"
                  first-day-of-week="1"
                  @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col v-if="showEndDate" cols="12" sm="6">
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(endDate)"
                    dense
                    outlined
                    v-bind="attrs"
                    :rules="validations.required"
                    v-on="on"
                    label="Voimassa päättyen"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  :allowed-dates="allowedDatesEnd"
                  v-model="endDate"
                  first-day-of-week="1"
                  @input="menuEnd = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <h4 class="mt-1">Lomake</h4>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="form"
                :items="activeForms"
                clearable
                item-value="_id"
                item-text="name"
                label="Lomake"
                outlined
                dense
                no-data-text="Ei lomakkeita"
                return-object
                hide-details
                :rules="validations.required"
              >
              </v-autocomplete>
              <WarningComponent v-if="selectedFormExists" class="mt-1"
                >Valittu lomake on jo liitetty ja korvataan uusilla tiedoilla, jos liität lomakkeen
                uudelleen.</WarningComponent
              >
            </v-col>
          </v-row>
          <h4 class="mt-1">Kuka vastaanottaa lomakkeen viestit?</h4>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="recipients"
                label="Sähköpostit"
                outlined
                dense
                placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
                :rules="validateEmails"
                hint="Erota sähköpostit pilkulla ja välilyönnillä"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- CURRENTFORMS -->
      <v-card-text v-show="tab == 1 && !editMode">
        <div v-for="(el, i) in currentForms" :key="'a' + i">
          <v-divider v-if="i == 0" style="margin-bottom: 10px"></v-divider>

          <div class="forms">
            <div class="headline-wrapper">
              <h3 class="mb-1">{{ el.form.name }}</h3>
              <p :class="formIsActive(el) ? 'success--text' : 'error--text'">
                {{ formIsActive(el) ? "Aktiivinen" : "Ei aktiivinen" }}
              </p>
              <p class="mb-1">
                Kopioi linkki
                <v-icon class="ml-1" small @click="copyLink(el)">mdi-content-copy</v-icon>
              </p>
              <div>
                <p><strong>Vastaanottajat: </strong></p>
                {{ el.recipients || "Ei vastaanottajia" }}
              </div>
            </div>

            <div class="text-wrapper" style="min-height: 25px">
              <div v-if="el.form.active">
                <p><strong>Aina aktiivinen:</strong> {{ formatBoolean(el.alwaysActive) }}</p>
                <div v-if="!el.alwaysActive">
                  <p>
                    <strong> Tyyppi: </strong>
                    {{ el.dates.endDate ? "Määräaikainen" : "Toistaiseksi voimassa oleva" }}
                  </p>
                  <p>
                    <strong> Voimassa alkaen: </strong>
                    {{ formatDate(el.dates.startDate) }}
                  </p>
                  <p v-if="el.dates.endDate">
                    <strong>Voimassa päättyen:</strong> {{ formatDate(el.dates.endDate) }}
                  </p>
                </div>

                <div v-if="el.reminders">
                  <p>
                    <strong>Muistutukset aktivoituna:</strong>
                    {{ formatBoolean(el.reminders.active) }}
                    <span v-if="!el.reminders.active && el.reminders.autoDeactivated">
                      (lomake täydennetty)</span
                    >
                  </p>
                  <div v-if="el.reminders.active && el.reminders.dates">
                    <p><strong>Tulevat muistutuspäivät:</strong></p>
                    <p
                      style="font-size: 12px"
                      v-for="(date, idx) in getFutureDates(el.reminders.dates)"
                      :key="'a' + idx"
                    >
                      {{ formatDate(date) }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="delete-icon">
                <v-btn small icon class="mr-1">
                  <v-icon
                    small
                    @click="
                      selectedForm = { ...el };
                      editMode = true;
                    "
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn small icon>
                  <v-icon color="error" small @click="removeForm(el._id)">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-divider style="margin-bottom: 9px; margin-top: 5px"></v-divider>
        </div>
      </v-card-text>

      <!-- // EDITMODE -->
      <v-card-text v-if="editMode">
        <h3 class="mb-2">Muokataan lomaketta: {{ selectedForm.form.name }}</h3>

        <h4>Kuka vastaanottaa lomakkeen viestit?</h4>
        <v-text-field
          v-model="selectedForm.recipients"
          class="mt-1"
          label="Sähköpostit"
          outlined
          dense
          placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
          :rules="validateEmails"
          hint="Erota sähköpostit pilkulla ja välilyönnillä"
          persistent-hint
        ></v-text-field>

        <v-checkbox
          v-if="
            attachTo === 'rentalContract' &&
            selectedForm.reminders.dates &&
            selectedForm.reminders.dates.length > 0 &&
            !selectedForm.reminders.autoDeactivated
          "
          label="Muistutukset käytössä"
          v-model="selectedForm.reminders.active"
        ></v-checkbox>
      </v-card-text>

      <v-card-actions v-if="editMode">
        <v-btn outlined color="error" @click="editMode = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-1" color="info" @click="editForm">Tallenna</v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="tab == 0" color="info" @click="save">Liitä</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "@/mixins/mixins";
import validations from "@/validations";
import moment from "moment";
export default {
  mixins: [mixins],

  props: {
    title: { type: String, default: "" },
    attachTo: { type: String, default: "" },
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tab: 0,
      tabs: ["Liitä", "Liitetyt"],
      showRecipientField: false,
      menuStart: false,
      menuEnd: false,
      selectedForm: null,
      editMode: false,
      showEndDate: false,
      valid: true,
      validations,
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.getActiveForms();
      } else {
        this.tab = 0;
      }
    },
  },

  computed: {
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("form", ["activeForms"]),

    selectedFormExists() {
      if (this.currentForms.length && this.form) {
        let formFound = false;

        this.$store.state.form.attachment[this.attachTo].forms.forEach((form) => {
          if (this.form._id == form.form._id) {
            formFound = true;
          }
        });

        return formFound;
      } else {
        return false;
      }
    },

    apartment() {
      return this.$store.state.form.attachment.apartment;
    },

    rentalContract() {
      return this.$store.state.form.attachment.rentalContract;
    },

    currentForms() {
      if (this.attachTo == "apartment" && this.apartment && this.apartment.forms) {
        return this.$store.state.form.attachment.apartment.forms;
      } else if (
        this.attachTo == "rentalContract" &&
        this.rentalContract &&
        this.rentalContract.forms
      ) {
        return this.$store.state.form.attachment.rentalContract.forms;
      } else {
        return [];
      }
    },

    validateEmails() {
      return [
        (v) =>
          !v ||
          v.split(", ").every((email) => validations.isValidEmail(email)) ||
          "Emailit on väärin muotoiltu",
      ];
    },

    form: {
      get() {
        return this.$store.state.form.attachment.form;
      },
      set(val) {
        this.setAttachment({ field: "form", val });
        this.recipients = val ? val.recipients : "";
      },
    },

    alwaysActive: {
      get() {
        return this.$store.state.form.attachment.alwaysActive;
      },
      set(val) {
        this.setAttachment({ field: "alwaysActive", val });
      },
    },

    startDate: {
      get() {
        return this.$store.state.form.attachment.dates.startDate;
      },
      set(val) {
        this.setAttachment({ field: "dates.startDate", val });
      },
    },

    endDate: {
      get() {
        return this.$store.state.form.attachment.dates.endDate;
      },
      set(val) {
        this.setAttachment({ field: "dates.endDate", val });
      },
    },

    recipients: {
      get() {
        return this.$store.state.form.attachment.recipients;
      },
      set(val) {
        this.setAttachment({ field: "recipients", val });
      },
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.resetForm();
        }
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("form", [
      "getActiveForms",
      "attachFormToApartment",
      "attachFormToRentalContract",
      "removeApartmentForm",
      "removeRentalContractForm",
      "editApartmentForm",
      "editRentalContractForm",
    ]),
    ...mapActions("apartment", ["searchApartment"]),
    ...mapMutations("form", ["setAttachment"]),

    async save() {
      if (this.$refs.form.validate() && this.checkDates()) {
        try {
          if (this.attachTo === "apartment") {
            await this.attachFormToApartment();
          } else if (this.attachTo === "rentalContract") {
            await this.attachFormToRentalContract();
          }
          this.resetForm();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async editForm() {
      try {
        if (this.attachTo === "apartment") {
          await this.editApartmentForm({
            form: this.selectedForm,
            apartmentId: this.apartment._id,
          });
        } else if (this.attachTo === "rentalContract") {
          await this.editRentalContractForm({
            form: this.selectedForm,
            contractId: this.rentalContract._id,
          });
        }

        this.editMode = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    checkDates() {
      if (this.startDate && this.endDate) {
        if (moment(this.endDate).isBefore(this.startDate)) {
          this.showPopup(
            "Voimassa päättyen ei voi olla sama tai aikaisempi päivä kuin alkaen päivä.",
            "error"
          );
          return false;
        }
      }
      return true;
    },

    getFutureDates(dates) {
      const today = moment().format("YYYY-MM-DD");
      return dates.filter((date) => moment(moment(date).format("YYYY-MM-DD")).isAfter(today));
    },

    allowedDatesStart(val) {
      const date = new Date(val);
      const today = new Date(moment().format("YYYY-MM-DD"));
      if (date >= today) return true;
      else return false;
    },

    allowedDatesEnd(val) {
      const date = new Date(val);
      const tomorrow = new Date(moment().format("YYYY-MM-DD"));
      if (date >= tomorrow) return true;
      else return false;
    },

    changeOpenAlways(e) {
      if (e) {
        this.startDate = null;
        this.endDate = null;
      }
    },

    formIsActive(form) {
      // Check current form status first
      if (!form.form.active) {
        return false;
      }

      const now = moment().format("YYYY-MM-DD");

      if (form.alwaysActive) {
        return true;
      } else {
        const startDate = form.dates.startDate;
        const endDate = form.dates.endDate;

        if (startDate && endDate) {
          const start = moment(startDate).format("YYYY-MM-DD");
          const end = moment(endDate).format("YYYY-MM-DD");
          return moment(now).isSameOrAfter(start) && moment(now).isSameOrBefore(end) ? true : false;
        } else if (startDate && !endDate) {
          const start = moment(startDate).format("YYYY-MM-DD");
          return moment(now).isSameOrAfter(start) ? true : false;
        } else {
          return false;
        }
      }
    },

    resetForm() {
      this.form = null;
      this.startDate = null;
      this.endDate = null;
      this.alwaysActive = false;
      this.recipients = "";
      this.$refs.form.resetValidation();
    },

    removeForm(id) {
      if (confirm("Haluatko varmasti poistaa lomakkeen sopimukselta?")) {
        if (this.attachTo === "apartment") {
          this.removeApartmentForm({ formId: id, apartmentId: this.apartment._id });
        } else if (this.attachTo === "rentalContract") {
          this.removeRentalContractForm({ formId: id, contractId: this.rentalContract._id });
        }
      }
    },

    linkToForm(item) {
      const host = window.location.host;

      if (this.attachTo === "apartment") {
        if (host.startsWith("localhost")) {
          return `http://${host}/lomakkeet/kohde/${this.apartment._id}/lomake/${item.form._id}`;
        } else {
          return `https://${host}/lomakkeet/kohde/${this.apartment._id}/lomake/${item.form._id}`;
        }
      } else if (this.attachTo === "rentalContract") {
        if (host.startsWith("localhost")) {
          return `http://${host}/lomakkeet/sopimus/${this.rentalContract._id}/lomake/${item.form._id}`;
        } else {
          return `https://${host}/lomakkeet/sopimus/${this.rentalContract._id}/lomake/${item.form._id}`;
        }
      }
    },

    copyLink(item) {
      const link = this.linkToForm(item);

      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.showPopup("Linkki kopioitu leikepöydälle", "success");
        })
        .catch(() => {
          this.showPopup("Ongelma linkin kopioimisessa", "error");
        });
    },
  },
};
</script>

<style scoped>
.forms {
  display: flex;
  flex-wrap: wrap;
}

.headline-wrapper {
  width: 200px;
  flex-shrink: 0;
  padding-right: 10px;
}

.text-wrapper {
  flex: 1;
  position: relative;
}

.delete-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 550px) {
  .submissions {
    flex-direction: column;
    align-items: flex-start;
  }

  .headline-wrapper {
    width: 100%;
  }
}
</style>
